import { vars } from '@iheartradio/web.accomplice';
import { Button } from '@iheartradio/web.accomplice/button';
import { Loading } from '@iheartradio/web.accomplice/icons';
import { Message } from '@iheartradio/web.accomplice/message';
import { Notification } from '@iheartradio/web.accomplice/notification';
import { Text } from '@iheartradio/web.accomplice/text';
import { Box, Dialog, TextInput, useToast } from '@iheartradio/web.companion';
import { isFunction } from '@iheartradio/web.utilities';
import { useFetcher } from '@remix-run/react';
import {
  type ReactNode,
  type Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isNullish } from 'remeda';
import { $path } from 'remix-routes';

import type {
  CreateCollectionAction,
  CreateCollectionActionData,
} from '~app/routes/api.v1.collection.create';
import { INPUT_MAX_LENGTH_DEFAULT } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';

export type CreatePlaylistSubmitCallback = (
  playlist: CreateCollectionActionData,
  closeDialog: () => void,
) => void;

// Either the `albumId` or `tracks` key should be set, but not both.
export type CreatePlaylistDialogProps = {
  onSubmit?: CreatePlaylistSubmitCallback;
  setMenuOpen?: (open: boolean) => void;
  trigger: ReactNode;
  albumId?: number;
  tracks?: number[];
};

export const CreatePlaylistDialog = (props: CreatePlaylistDialogProps) => {
  const { onSubmit, trigger, setMenuOpen, tracks, albumId, ...restProps } =
    props;

  const [open, setOpen] = useState(false);
  const [playlistNameIsValid, setPlaylistNameIsValid] = useState(false);
  const ref = useRef<HTMLFormElement>();
  const [characterCount, setCharacterCount] = useState(0);

  const toast = useToast();
  const createCollection = useFetcher<CreateCollectionAction>();

  const isSubmitting = createCollection.state === 'submitting';
  const isLoading = createCollection.state === 'loading';
  const isDone =
    createCollection.state === 'idle' && createCollection.data != null;
  const isSuccessful = isDone && createCollection.data?.ok;

  const errorMessage =
    createCollection.data?.ok ? null : createCollection.data?.errorMessage;

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback<CreatePlaylistSubmitCallback>(
    (data, closeDialog) => {
      if (data.ok) {
        closeDialog();

        toast(dismiss => (
          <Notification
            actions={[
              {
                kind: 'tertiary',
                size: 'large',
                color: 'gray',
                textColor: vars.color.gray600,
                href: buildPlaylistUrl(data.collection),
                content: 'Go to playlist',
              },
            ]}
            kind="success"
            onDismiss={dismiss}
          >
            <Text
              as="p"
              css={{ color: vars.color.gray600 }}
              kind={{ mobile: 'body-4', medium: 'body-3' }}
            >
              Playlist created
            </Text>
          </Notification>
        ));
      }

      setMenuOpen?.(false);
    },
    [],
  );

  // Reset the form on submission
  useEffect(() => {
    if (isSuccessful) {
      ref?.current?.reset();
    }
  }, [isSuccessful]);

  useEffect(() => {
    if (isDone && createCollection?.data) {
      if (isFunction(onSubmit)) {
        onSubmit(createCollection.data, closeDialog);
      } else {
        handleSubmit(createCollection?.data, closeDialog);
      }
    }
  }, [createCollection, closeDialog, isDone]);

  return (
    <Dialog.Root
      onOpenChange={setOpen}
      open={open}
      trigger={trigger}
      {...restProps}
    >
      <createCollection.Form
        action={$path('/api/v1/collection/create')}
        method="POST"
        ref={ref as Ref<HTMLFormElement>}
      >
        <Dialog.Metadata>
          <Dialog.Title>Create New Playlist</Dialog.Title>
          <Box>
            {/* TODO: Replace with TextField after Dialog Refactor */}
            <TextInput
              label="Playlist Name"
              maxLength={INPUT_MAX_LENGTH_DEFAULT}
              name="name"
              onChange={value => {
                setCharacterCount(value.length);
                setPlaylistNameIsValid(value.trim() !== '');
              }}
              placeholder="Enter playlist name"
              required
            />
            {isNullish(albumId) && tracks ?
              tracks.map((trackId, index) => (
                <input
                  key={trackId}
                  name={`tracks[${index}]`}
                  type="hidden"
                  value={trackId}
                />
              ))
            : albumId && !tracks?.length ?
              <input name="albumId" type="hidden" value={albumId} />
            : null}
            <Message kind="neutral">
              {characterCount} / {INPUT_MAX_LENGTH_DEFAULT}
            </Message>
          </Box>
          {errorMessage ?
            <Message key={errorMessage} kind="error">
              {errorMessage}
            </Message>
          : null}
          <Dialog.ButtonContainer flexDirection="row">
            <Button
              color="white"
              inline
              kind="secondary"
              onPress={() => {
                ref?.current?.reset();
                setOpen(false);
              }}
              size={{ xsmall: 'small', medium: 'large' }}
              type="reset"
            >
              Cancel
            </Button>
            <Button
              color="red"
              inline
              isDisabled={isSubmitting || isLoading || !playlistNameIsValid}
              kind="primary"
              size={{ xsmall: 'small', medium: 'large' }}
              type="submit"
            >
              {createCollection.state === 'idle' ?
                'Create playlist'
              : <Loading size={16} />}
            </Button>
          </Dialog.ButtonContainer>
        </Dialog.Metadata>
      </createCollection.Form>
    </Dialog.Root>
  );
};
